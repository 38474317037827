import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AccountDeletion from './Pages/AccountDeletion/AccountDeletion';
import Home from './Pages/Home/Home';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/delete-account-request" element={<AccountDeletion />} />
      </Routes>
    </Router>
  );
}

export default App;

